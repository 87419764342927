import MappedProductInfo from './MappedProductInfo';
import PossibleMatchesTable from './PossibleMatchesTable';
import RetailProductComponent from './RetailProductComponent';
import SearchMapProductModal from './SearchMapProductModal';
import { RetailProduct } from './types';
import { previousSearchAction } from '../../../models/model_products_paginated';
import {
  getRetailProduct,
  matchRetailProduct,
  unmatchRetailProduct,
  updateSparkRefinedSize,
} from '../../../services/api/retailProducts';
import { RetailProductStatusEnum } from '../../../utils/enum';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal, Button, Empty, Spin, notification } from 'antd';
import { AxiosError } from 'axios';
import { doAction } from 'module-reaction';
import React, { useState, useRef, useEffect } from 'react';
export interface SizeProps {
  amount?: string;
  unit?: string;
}

export const isSizeSetCorrectly = (size?: SizeProps) =>
  size !== undefined && size.amount !== undefined && size.amount !== '';

const ApproveModal = ({
  visible,
  onCancel,
  selectedProduct,
  onAddProduct,
}: {
  visible: boolean;
  onCancel: () => void;
  selectedProduct: RetailProduct;
  onAddProduct: (retailProduct: RetailProduct) => void;
}) => {
  const [customHeight, setCustomHeight] = useState(300);
  const [selectedMatch, setSelectedMatch] = useState<any>(null);
  const [currentStatus, setCurrentStatus] = useState<RetailProductStatusEnum>(
    (selectedProduct && selectedProduct?.status) || ''
  );
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [openSearchMapProductModal, setOpenSearchMapProductModal] = useState(false);
  const [overrideSize, setOverrideSize] = useState(
    selectedProduct.sparkRefinedFields?.size?.amount || ''
  );

  const ref = useRef<HTMLDivElement>(null);
  const refWrapper = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();

  const id = selectedProduct ? selectedProduct.id : '';

  const { isLoading, error, data } = useQuery({
    queryKey: ['getRetailProduct', id],
    queryFn: () => getRetailProduct({ id }),
    refetchOnWindowFocus: false,
  });

  const unMatchMutation = useMutation({
    mutationFn: (updateRetailProduct: { id: string; retailLocationId: string; status: string }) =>
      unmatchRetailProduct(updateRetailProduct),
    onSuccess: () => {
      onCancel();
      queryClient.invalidateQueries({ queryKey: ['getRetailProducts'] });
    },
  });

  const matchMutation = useMutation({
    mutationFn: (updateRetailProduct: {
      id: string;
      retailLocationId: string;
      jointlyProductId: string;
    }) => matchRetailProduct(updateRetailProduct),
    onSuccess: () => {
      onCancel();
      notification.success({ message: 'Success matching' });
      queryClient.invalidateQueries({ queryKey: ['getRetailProducts'] });
    },
    onError: (err: AxiosError) => {
      notification.error({
        message: err?.response?.data?.message || 'Something went wrong matching!',
      });
    },
  });

  const updateSparkRefinedSizeMutation = useMutation({
    mutationFn: (updateSparkRefinedSizePayload: {
      id: string;
      retailLocationId: string;
      sparkRefinedSize: string;
    }) => updateSparkRefinedSize(updateSparkRefinedSizePayload),
    onSuccess: () => {
      notification.success({ message: 'Size updated successfully' });
      queryClient.invalidateQueries({ queryKey: ['getRetailProduct', id] });
      queryClient.invalidateQueries({ queryKey: ['getRetailProducts'] });
    },
    onError: (err: AxiosError) => {
      notification.error({
        message: err?.response?.data?.message || 'Something went wrong updating size!',
      });
    },
  });

  useEffect(() => {
    setCustomHeight(ref?.current?.clientHeight || 300);
  }, [data]);

  if (!selectedProduct) return <div />;

  const {
    name,
    brand,
    imageUrls,
    type,
    sku,
    size,
    cannabinoids,
    description,
    terpenes,
    flavors,
    effects,
    strain,
  } = selectedProduct;

  const { sparkRefinedFields } = data || {};

  const metadata = {
    name,
    brand,
    size,
    sku,
    type,
    cannabinoids,
    description,
    terpenes,
    flavors,
    effects,
    strain,
  };

  return (
    <Modal
      title={selectedProduct.name}
      open={visible}
      width={'90%'}
      onCancel={onCancel}
      footer={[
        ...(selectedMatch
          ? [
              <Button
                type="primary"
                key="match"
                loading={matchMutation.isLoading}
                onClick={() => {
                  matchMutation.mutate({
                    id: selectedProduct.id,
                    retailLocationId: selectedProduct.retailLocationId,
                    jointlyProductId: selectedMatch._id,
                  });
                }}
                disabled={!isSizeSetCorrectly(size)}
              >
                Match
              </Button>,
            ]
          : []),
        ...(!!unsavedChanges && !selectedMatch
          ? [
              <Button
                key="save"
                type="primary"
                loading={unMatchMutation.isLoading}
                onClick={() => {
                  unMatchMutation.mutate({
                    id: selectedProduct.id,
                    retailLocationId: selectedProduct.retailLocationId,
                    status: currentStatus,
                  });
                }}
              >
                Save
              </Button>,
            ]
          : []),
        ...([RetailProductStatusEnum.NotMapped, RetailProductStatusEnum.PreMapped].includes(
          currentStatus
        )
          ? [
              <Button
                key="add product"
                type="primary"
                onClick={() => {
                  onAddProduct(selectedProduct);
                }}
              >
                Add product
              </Button>,
            ]
          : []),
        <Button key="back" onClick={onCancel}>
          Return
        </Button>,
      ]}
    >
      {isLoading && <Spin />}

      {!isLoading && (!data || error) && <Empty />}

      {!isLoading && data && !error && (
        <div
          style={{
            width: '100%',
            overflow: 'auto',
            display: 'flex',
          }}
          ref={refWrapper}
        >
          <RetailProductComponent
            reference={ref}
            currentStatus={currentStatus}
            name={sparkRefinedFields?.name || ''}
            brand={sparkRefinedFields?.brand || ''}
            size={sparkRefinedFields?.size || { amount: '' }}
            imageUrls={imageUrls}
            metadata={metadata}
          />
          <div
            style={{
              flex: 0.4,
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div style={{ height: '90%', width: 2, background: 'gray' }} />
          </div>
          {[RetailProductStatusEnum.NotMapped, RetailProductStatusEnum.PreMapped].includes(
            currentStatus
          ) ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 12,
                overflow: 'auto',
                height: customHeight,
              }}
            >
              <div style={{ display: 'flex' }}>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    marginBottom: 0,
                    textDecoration: 'underline',
                    marginRight: 20,
                  }}
                >
                  Possible matching products
                </p>
                <Button
                  type="primary"
                  key="match"
                  onClick={() => setOpenSearchMapProductModal(true)}
                  disabled={!isSizeSetCorrectly(size)}
                >
                  Search Match
                </Button>
              </div>
              <PossibleMatchesTable
                possibleMatchingProducts={
                  // Workaround to display elements in the same order they were matched
                  selectedProduct.possibleJointlyMappedIds.map((item: string) =>
                    data.possibleJointlyMappedIds.find((element: any) => element._id === item)
                  ) || []
                }
                selectedMatch={selectedMatch}
                setSelectedMatch={setSelectedMatch}
                idField="_id"
              />
              <SearchMapProductModal
                retailProductInfo={{
                  reference: ref,
                  currentStatus,
                  name: name,
                  brand: sparkRefinedFields?.brandMatchName || sparkRefinedFields?.brand,
                  type: sparkRefinedFields?.type?.name,
                  size: size,
                  imageUrls,
                  metadata,
                }}
                unmatchedProduct={data}
                height={refWrapper?.current?.clientHeight}
                visible={openSearchMapProductModal}
                onOk={() => {
                  setOpenSearchMapProductModal(false);
                  onCancel();
                }}
                onCancel={() => {
                  doAction(previousSearchAction, {
                    search_key: undefined,
                    brand: undefined,
                    product_type: undefined,
                    productSpecific: undefined,
                    states: undefined,
                    store_url: undefined,
                    admin: undefined,
                    newCannabinoid: undefined,
                  });
                  setOpenSearchMapProductModal(false);
                }}
              />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 8,
                  overflow: 'auto',
                  height: customHeight,
                }}
              >
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    marginBottom: 0,
                    textDecoration: 'underline',
                  }}
                >
                  Matched product
                </p>
                <MappedProductInfo
                  mappedProduct={data.jointlyProductId}
                  onUnmatch={() => {
                    setUnsavedChanges(true);
                    setCurrentStatus(RetailProductStatusEnum.NotMapped);
                  }}
                />
                <div style={{ marginBottom: 20, marginTop: 100, textAlign: 'center' }}>
                  <label>Override AI generated Size information</label>
                  <input
                    type="text"
                    value={overrideSize}
                    onChange={(e) => setOverrideSize(e.target.value)}
                    style={{ marginLeft: 10, width: 250 }}
                  />
                  <Button
                    type="primary"
                    onClick={() =>
                      updateSparkRefinedSizeMutation.mutate({
                        id: selectedProduct.id,
                        retailLocationId: selectedProduct.retailLocationId,
                        sparkRefinedSize: overrideSize,
                      })
                    }
                    style={{ marginLeft: 10 }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ApproveModal;
