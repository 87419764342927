import { EligibleZipCode } from '../../RetailLocationModal';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { FC } from 'react';

export type EligibleZipCodeInputProps = {
  initialValue?: EligibleZipCode[];
  onChange?: (value: EligibleZipCode[]) => void;
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const EligibleZipCodeInput: FC<EligibleZipCodeInputProps> = () => (
  <>
    <Form.List
      name="eligibleZipCodes"
      rules={[
        {
          validator: async (_, zipCodes) => {
            const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;

            const invalidZipCodes = zipCodes.filter(
              (zipCode: EligibleZipCode) => zipCode?.value && !zipCodeRegex.test(zipCode.value)
            );

            const invalidMins = zipCodes.filter(
              (zipCode: EligibleZipCode) =>
                zipCode?.min === undefined ||
                zipCode?.min === null ||
                zipCode?.min < 0 ||
                zipCode?.min > 99999
            );

            if (invalidZipCodes.length > 0) {
              return Promise.reject(
                new Error(
                  `Invalid zip codes: ${invalidZipCodes
                    .map((item: EligibleZipCode) => item?.value)
                    .join(', ')}. Please enter valid zip codes.`
                )
              );
            } else if (invalidMins.length > 0) {
              return Promise.reject(
                new Error(
                  `Invalid mins: ${invalidMins
                    .map((item: EligibleZipCode) => item?.min)
                    .join(', ')}. Please enter valid mins.`
                )
              );
            }
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item
              data-testid={`eligibleZipCode-${index}`}
              {...formItemLayout}
              required={false}
              key={field.key}
            >
              <div style={{ display: 'flex', flexDirection: 'row', gap: 40 }}>
                <Form.Item
                  {...field}
                  key={field.key + 'value'}
                  name={[field.name, 'value']}
                  validateTrigger={['onBlur']}
                  noStyle
                  rules={[{ required: true, message: 'Value is required' }]}
                >
                  <Input placeholder="value" style={{ width: '30%' }} />
                </Form.Item>
                <Form.Item
                  {...field}
                  key={field.key + 'min'}
                  name={[field.name, 'min']}
                  validateTrigger={['onBlur']}
                  noStyle
                  rules={[{ required: true, message: 'Min is required' }]}
                >
                  <Input placeholder="min" style={{ width: '30%' }} type="number" />
                </Form.Item>
                <MinusCircleOutlined
                  data-testid={`removeEligibleZipCodeBtn-${index}`}
                  style={{ fontSize: 30 }}
                  onClick={() => remove(field.name)}
                />
              </div>
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              data-testid="addEligibleZipCode"
              type="dashed"
              onClick={() => add()}
              style={{ width: '60%' }}
              icon={<PlusOutlined />}
            >
              Add field
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  </>
);

export default EligibleZipCodeInput;
